<!-- 这个原为是页面 改成组件引入了 -->
<template>
    <div style="text-align: left;">

        <div class="spec-menu-container">
            <el-select v-model="classifyChoose" @change="queryUserSpecTemplate" placeholder="请选择模板所在的产品分类">
                <el-option
                        v-for="item in classifyData"
                        :key="item.id"
                        :label="item.classifyName"
                        :value="item.id">
                </el-option>
            </el-select>
            <el-button @click="addSpecTemp" icon="el-icon-circle-plus" type="primary" :disabled="!classifyChoose">创建新的模板</el-button>
        </div>


        <div style="width: 100%;min-height: 700px;">

            <template v-if="tempData">
                <template v-for="(item,index) in tempData.content">

                    <el-card shadow="never" style="margin-bottom: 25px;">
                        <div slot="header" class="my-header">
                            <div>{{item.templateName}}</div>
                            <div>
                                <el-button @click="editSpecTemp(item.id, item.templateName)" type="text">修改</el-button>
                                <el-button @click="deleteSpecTemp(item.id)" type="text">删除</el-button>
                                <el-button @click="addSpecOption(item.id, item.templateName)" type="text">添加参数</el-button>
                            </div>
                        </div>
                        <div style="min-height: 100px;">
                            <template v-if="item.specificationOptionDtos">
                                <el-table
                                        :data="item.specificationOptionDtos"
                                        border
                                        style="width: 100%;">
                                    <el-table-column type="index" width="50"></el-table-column>
                                    <el-table-column label="参数名" prop="optionName"></el-table-column>
                                    <el-table-column label="参数值" prop="optionValue"></el-table-column>
                                    <el-table-column label="操作" width="205" align="center">
                                        <template slot-scope="scope">
                                            <el-button @click="editSpecOption(item.id, item.templateName, scope.row.id, scope.row.optionName, scope.row.optionValue)" icon="el-icon-edit" size="mini">编辑</el-button>
                                            <el-button @click="deleteSpecOption(scope.row.id)" icon="el-icon-delete" size="mini">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </div>
                    </el-card>

                </template>
            </template>

        </div>


        <div>
            <el-divider></el-divider>
            <el-pagination
                    v-if="tempData"
                    background
                    layout="sizes, prev, pager, next, jumper, ->, total, slot"
                    :page-size="tempData.size"
                    :total="tempData.totalElements"
                    @size-change="sizeChange"
                    @current-change="currentChange"
                    @prev-click="currentChange"
                    @next-click="currentChange">
            </el-pagination>
        </div>

        <el-dialog
                :title="specOptionDialogAdd?'添加模板参数':'修改模板参数'"
                :visible.sync="specOptionDialogVisible"
                center
                width="500px">
            <div>
                <el-form :model="formSpecOption" ref="formSpecOption" :rules="formSpecOptionRule" label-width="120px;">
                    <el-form-item label="模版名" prop="tempId">
                        <el-input v-model="formSpecOption.tempName" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="参数名" prop="optionName">
                        <el-input v-model="formSpecOption.optionName" placeholder="请设置参数名"></el-input>
                    </el-form-item>
                    <el-form-item label="参数值" prop="optionValue">
                        <el-input v-model="formSpecOption.optionValue" placeholder="请设置参数值"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="keywordFiltr('submitSpecOption')">确 定</el-button>
                <el-button @click="specOptionDialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
    import oucy from "../../../../util/oucyUtil";
    import {furnitureClassify,furnitureSpecificationOption} from "@/service"
    import {localSet, localGet, localDel} from "@/store/store"

    export default {
        name: "Spec",
        data(){
            return{
                classifyData:[],
                classifyChoose:null,
                tempData:[],
                pageSize: 10,
                pageNow: 0,
                formSpecOption:{
                    id:null,
                    tempId:null,
                    tempName:null,
                    optionName:null,
                    optionValue:null
                },
                formSpecOptionRule:{
                    tempId: [
                        {required: true, message: '请设置模板名', trigger: 'blur'},
                    ],
                    optionName: [
                        {required: true, message: '请设置参数名', trigger: 'blur'},
                    ],
                    optionValue: [
                        {required: true, message: '请设置参数值', trigger: 'blur'},
                    ],
                },
                specOptionDialogAdd:true,
                specOptionDialogVisible:false
            }
        },
        mounted() {
            const me = this;
            let enterprise=localGet('enterprise')
            this.enterpriseId=enterprise.id
            furnitureClassify.findByLtUserAuthAndClassifyCategory({enterpriseId:this.enterpriseId}).then(res=>{
            // oucy.queryUserClassify(null).then(res=>{
                console.log(res);
                me.classifyData = res;
                return me.queryUserSpecTemplate();
            });
        },
        methods:{
            keywordFiltr(fn){
                console.log('this.formSpecOption',this.formSpecOption);
                this.$oucy.keywordFiltr(this.formSpecOption).then(res=>{
                   this[fn]() 
                },err=>{})
            },
            addSpecTemp: function () {
                const me = this;
                me.$prompt('模板名称', '创建参数模版', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /^\S+$/,
                    inputErrorMessage: '模板名称不正确'
                }).then(({ value }) => {
                    oucy.postRequest('/client/product/furniturespecificationoption/addFurnitureSpecTemplate',{
                        furnitureClassifyId:me.classifyChoose,
                        enterpriseId:this.enterpriseId,
                        templateName:value
                    }).then(res=>{
                        me.queryUserSpecTemplate();
                    });
                });
            },
            editSpecTemp: function(tmpId, defaultName){
                const me = this;
                me.$prompt('模板名称', '修改模版名称', {
                    inputValue: defaultName,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /^\S+$/,
                    inputErrorMessage: '模板名称不正确'
                }).then(({ value }) => {
                    oucy.postRequest('/client/product/furniturespecificationoption/updateFurnitureSpecTemplate',{
                        id:tmpId,
                        enterpriseId:this.enterpriseId,
                        templateName:value
                    }).then(res=>{
                        me.queryUserSpecTemplate();
                    });
                });
            },
            deleteSpecTemp: function(tmpId){
                const me = this;
                oucy.postRequest('/client/product/furniturespecificationoption/deleteFurnitureSpecTemplate',{
                    enterpriseId:this.enterpriseId,
                    id:tmpId
                }).then(res=>{
                    me.queryUserSpecTemplate();
                });
            },
            sizeChange: function(pageSize) {
                const me = this;
                me.pageSize = pageSize;
                me.queryUserSpecTemplate();
            },
            currentChange: function(current) {
                const me = this;
                me.pageNow = current - 1;
                me.queryUserSpecTemplate();
            },
            indexMethod: function(index) {
                const me = this;
                return me.pageSize * me.pageNow + index + 1;
            },
            queryUserSpecTemplate: function () {
                const me = this;
                return new Promise((resolve, reject) => {
                    if(me.classifyChoose){
                        furnitureSpecificationOption.getAllEnterpriseInfo({
                            furnitureClassifyId:me.classifyChoose,
                            start:me.pageNow, 
                            limit:me.pageSize,
                            enterpriseId:this.enterpriseId}).then(res=>{
                            if(res.totalElements == 0) {
                                res.content = [];
                            }
                            me.tempData = res;
                            console.log(me.tempData);
                            resolve(res);
                        }).catch(err=>{
                            reject(err);
                        })
                    }else{
                        resolve();
                    }

                })

            },
            addSpecOption: function (tempId, tempName) {
                const me = this;
                me.specOptionDialogAdd = true;
                me.specOptionDialogVisible = true;
                me.formSpecOption.id = null;
                me.formSpecOption.tempId = tempId;
                me.formSpecOption.tempName = tempName;
                me.formSpecOption.optionName = null;
                me.formSpecOption.optionValue = null;
            },
            editSpecOption: function(tempId, tempName, id, optionName, optionValue){
                const me = this;
                me.specOptionDialogAdd = false;
                me.specOptionDialogVisible = true;
                me.formSpecOption.id = id;
                me.formSpecOption.tempId = tempId;
                me.formSpecOption.tempName = tempName;
                me.formSpecOption.optionName = optionName;
                me.formSpecOption.optionValue = optionValue;
            },
            deleteSpecOption: function(id){
                const me = this;
                oucy.postRequest('/client/product/furniturespecificationoption/delFurnitureSpecificationOption',{
                    enterpriseId:this.enterpriseId,
                    id:id,
                }).then(res=>{
                    me.queryUserSpecTemplate();
                });
            },
            submitSpecOption: function () {
                const me = this;
                me.$refs["formSpecOption"].validate((valid) => {
                    if (valid) {
                        let has=false
                        for(let v in this.tempData.content){
                            if(me.formSpecOption.tempId==v.id){
                                for(let vv in v.specificationOptionDtos){
                                    if(vv.optionName == me.formSpecOption.optionName){
                                        has=true
                                    }
                                }
                            console.log(v)
                            }
                        }
                        if( has){
                            this.$message('参数名 "'+me.formSpecOption.optionName+'" 已存在')
                            return
                        }
                        // return
                        me.specOptionDialogVisible = false;
                        oucy.postRequest(me.specOptionDialogAdd?'/client/product/furniturespecificationoption/addFurnitureSpecificationOption':'/client/product/furniturespecificationoption/updateFurnitureSpecificationOption',{
                            tempId:me.formSpecOption.tempId,
                            enterpriseId:this.enterpriseId,
                            id:me.formSpecOption.id,
                            optionName:me.formSpecOption.optionName,
                            optionValue:me.formSpecOption.optionValue
                        }).then(res=>{
                            me.queryUserSpecTemplate();
                        })
                    }
                });
            },
            getSpanArr: function(){
                const me = this;

            },
        }
    }
</script>

<style scoped>
.spec-menu-container{
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}
.my-header{
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
}

</style>
